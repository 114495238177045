<template>
    <v-row v-if="!edit">
        <v-col cols="12">
            <v-icon @click="editMode" small color="primary">mdi-pencil</v-icon>
            {{ this.parentItem.link.cpa_network.label }}: {{ this.parentItem.link.label }}
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12" md="4">
            <v-select
                    v-model="parentItem.offer_description_id"
                    :items="options.offerDescriptions"
                    :label="trans('fields.common.offerDescription')"
                    :error-messages="errors.offer_description_id"
                    @input="clearError('offer_description_id')"
                    @change="clearError('offer_description_id')"
                    dense
            >
                <template v-slot:append-outer>
                    <v-slide-x-reverse-transition
                            mode="out-in"
                    >
                        <v-icon v-if="parentItem.offer_description_id" @click="offerDescriptionDialogShow" color="primary">mdi-pencil</v-icon>
                        <v-icon v-else @click="offerDescriptionDialogShow" color="primary">mdi-plus</v-icon>
                    </v-slide-x-reverse-transition>
                </template>
            </v-select>
        </v-col>
        <v-col cols="12" md="4">
            <v-autocomplete
                v-if="user_id"
                :items="options.cpaNetworks"
                v-model="cpa_network_id"
                :label="trans('fields.common.cpaNetwork')"
                :error-messages="errors.link_id"
                @input="clearError('link_id')"
                @change="clearError('link_id')"
                dense
            >
                <template v-slot:append-outer>
                    <v-slide-x-reverse-transition
                        mode="out-in"
                    >
                        <v-icon @click="cpaNetworkDialogShow" color="primary">mdi-plus</v-icon>
                    </v-slide-x-reverse-transition>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
            <v-autocomplete
                v-if="user_id && cpa_network_id"
                :items="links"
                :key="cpa_network_id"
                v-model="parentItem.link_id"
                :label="trans('fields.common.link')"
                :error-messages="errors.link_id"
                @input="clearError('link_id')"
                @change="clearError('link_id')"
                dense
            >
                <template v-slot:item="data">
                    <v-list-item-content>
                        <v-list-item-title v-text="data.item.text"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.url"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>
                <template v-slot:append-outer>
                    <v-slide-x-reverse-transition
                        mode="out-in"
                    >
                        <v-icon @click="linkDialogShow" color="primary">mdi-plus</v-icon>
                    </v-slide-x-reverse-transition>
                </template>
            </v-autocomplete>
        </v-col>
        <v-dialog v-model="offerDescriptionDialog" max-width="800px">
            <offer-description-form
                    dense
                    v-if="offerDescriptionDialogLoaded"
                    :offer_id="parentItem.offer_id"
                    :item-id="offer_description_id"
                    v-on:saved="offerDescriptionDialogOnSave"
                    v-on:close="offerDescriptionDialogClose"
            />
        </v-dialog>
        <v-dialog v-model="cpaNetworkDialog" max-width="800px">
            <cpa-network-form
                v-if="cpaNetworkDialogLoaded"
                :user_id="user_id"
                v-on:saved="cpaNetworkDialogOnSave"
                v-on:close="cpaNetworkDialogClose"
            />
        </v-dialog>
        <v-dialog v-model="linkDialog" max-width="800px">
            <link-form
                v-if="linkDialogLoaded"
                :user_id="user_id"
                :cpa_network_id="cpa_network_id"
                :offer_id="parentItem.offer_id"
                v-on:saved="linkDialogOnSave"
                v-on:close="linkDialogClose"
            />
        </v-dialog>

    </v-row>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    import OfferDescriptionForm from "@/components/user/offerDescription/OfferDescriptionForm";

    export default {
        name: "OfferAppearanceForm",
        mixins: [formValidate],
        components: {
            OfferDescriptionForm,
            CpaNetworkForm: () => import('@/components/admin/cpaNetwork/CpaNetworkForm'),
            LinkForm: () => import('@/components/admin/link/LinkForm'),
        },
        props: {
            user_id: {
                type: Number
            },
            parentOptions: {
                type: [Object, undefined]
            },
            // offer appearance object {value text}
            parentItem: {
                type: [Object, undefined]
            },
            parentErrors: {
                type: [Object, undefined]
            }
        },
        data(){
            return {
                options: {},

                cpa_network_id: this.parentItem.link ? this.parentItem.link.cpa_network_id : null,
                offer_description_id: this.parentItem.offer_description_id ? this.parentItem.offer_description_id : null,
                links: [],

                edit: this.parentItem.link ? false : true,

                cpaNetworkDialog: false,
                cpaNetworkDialogLoaded: false,
                offerDescriptionDialog: false,
                offerDescriptionDialogLoaded: false,
                linkDialog: false,
                linkDialogLoaded: false,
            }
        },
        created() {
            if (this.edit) {
                this.load();
            }
        },
        watch: {
            cpa_network_id(nv, ov){
                if (nv !== ov){
                    this.parentItem.link_id = null;
                    this.loadLinks();
                }
            },
            parentErrors(nv){
                this.errors = nv;
            }
        },
        methods: {
            load(){
                this.loadOptions();
                if (this.cpa_network_id) this.loadLinks();
            },
            loadOptions(force = false){
                let needLoad = {};
                if (this.parentOptions && !force){
                    if (this.parentOptions.cpaNetworks){
                        this.options.cpaNetworks = this.parentOptions.cpaNetworks;
                    } else {
                        needLoad.cpaNetworks = this.user_id;
                    }
                } else {
                    needLoad.cpaNetworks = this.user_id;
                }
                needLoad.offerDescriptions = {offer_id: this.parentItem.offer_id, user_id: this.parentItem.landing.user_id};
                if (Object.keys(needLoad).length){
                    axios.post(this.route('admin.options'), needLoad).then( response => {
                        this.options = {
                            ...this.options,
                            ...response.data
                        }
                    })
                }
            },
            loadLinks(){
                if (this.cpa_network_id){
                    axios.post(this.route('admin.options'), {
                        links: {
                            user_id: this.user_id,
                            offer_id: this.parentItem.offer_id,
                            cpa_network_id: this.cpa_network_id,
                        }
                    }).then(response => {
                        this.links = response.data.links;
                    }).catch(err => {
                        console.log(err);
                    })
                }
            },
            editMode(){
                this.load();
                this.edit = true;
            },
            cpaNetworkDialogShow(){
                this.cpaNetworkDialogLoaded = true;
                this.cpaNetworkDialog = true;
            },
            cpaNetworkDialogClose(){
                this.cpaNetworkDialog = false;
                setTimeout(() => {
                    this.cpaNetworkDialogLoaded = false;
                }, 300);
            },
            offerDescriptionDialogShow(){
                this.offerDescriptionDialogLoaded = true;
                this.offerDescriptionDialog = true;
            },
            offerDescriptionDialogClose(){
                this.offerDescriptionDialog = false;
                setTimeout(() => {
                    this.offerDescriptionDialogLoaded = false;
                }, 300);
            },
            offerDescriptionDialogOnSave(id){
                this.offerDescriptionDialogClose();
                this.loadOptions(true);
                this.offer_description_id = id;
                this.$emit('offerDescriptionSaved')
            },

            cpaNetworkDialogOnSave(id){
                this.cpaNetworkDialogClose();
                this.loadOptions(true);
                this.cpa_network_id = id;
                this.$emit('cpaNetworkSaved')
            },
            linkDialogShow(){
                this.linkDialogLoaded = true;
                this.linkDialog = true;
            },
            linkDialogClose(){
                this.linkDialog = false;
                setTimeout(() => {
                    this.linkDialogLoaded = false;
                }, 300);
            },
            linkDialogOnSave(id){
                this.linkDialogClose();
                this.loadOptions(true);
                this.loadLinks();
                this.parentItem.link_id = id;
                this.$emit('linkSaved')
            },
        },
    }
</script>
