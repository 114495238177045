<template>
    <v-card
            :loading="!loaded"
    >
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text v-if="loaded">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                                v-model="item.name"
                                :label="trans('fields.offerDescription.name')"
                                :error-messages="errors.name"
                                @input="clearError('name')"
                        ></v-text-field>
                        <v-text-field
                                v-model="item.label"
                                :label="trans('fields.common.label')"
                                :error-messages="errors.label"
                                @input="clearError('label')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="offer.type_id">
                        <v-row>
                            <v-col
                                    v-for="(field, itemName) in typeFields"
                                    :key="itemName"
                                    style="min-width: 300px;"
                            >
                                <offer-field :item="field" :item-name="itemName" :model="item" :languageLocales="options.languageLocalesKeyed" ></offer-field>
                            </v-col>
                        </v-row>


                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close', itemId)">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="item.id" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "OfferDescriptionForm",
        mixins: [formValidate],
        components: {
            OfferField: () => import('@/components/commonComponents/OfferField'),
        },
        props: {
            itemId: {
                type: [Number, String, null, undefined],
            },
            offer_id: {
                type: [Number]
            }
        },
        data(){
            return {
                offerShowRoute: 'user.offers.show',
                showRoute: 'user.offerDescriptions.show',
                storeRoute: 'user.offerDescriptions.store',
                updateRoute: 'user.offerDescriptions.update',
                item: {
                    id: this.itemId,
                    name: '',
                    label: "",
                    offer_id: this.offer_id ? this.offer_id : null,
                    fields: {},
                    language_id: null,
                },
                offer: null,
                error: null,
                options: null,
                loaded: false,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                if (this.item.id) {
                    axios.get(this.route(this.showRoute, this.item.id)).then(response => {
                        this.item = response.data;
                        axios.get(this.route(this.offerShowRoute, this.item.offer_id)).then( response => {
                            this.offer = response.data;
                            this.item.language_id = this.offer.language_id;
                            this.loadOptions();
                        }).catch( err => {
                            console.log(err);
                        })
                    }).catch(err => {
                        this.error = err.response.data;
                    });
                } else {
                    axios.get(this.route(this.offerShowRoute, this.item.offer_id)).then( response => {
                        this.offer = response.data;
                        this.item.fields = this.offer.fields;
                        this.item.label = this.offer.label;
                        this.item.language_id = this.offer.language_id;
                        this.loadOptions();
                    }).catch( err => {
                        console.log(err);
                    })
                }
            },
            loadOptions(){
                axios.post(this.route('user.options'), {
                    offerTypes: true,
                    languages: true,
                }).then( response => {
                    this.options = response.data;
                    this.loaded = true;
                })
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route(this.storeRoute), this.item).then(( response ) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                } else {
                    axios.post(this.route(this.updateRoute, this.item.id), this.item).then(( response ) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                }
            },
            setDefaultFields(offerTypeId){
                let defaultFields = {};
                let data = this.options.offerTypesFields[offerTypeId];
                _.defaultsDeep(defaultFields, data.dummy);
                this.item.fields = defaultFields;
            },
        },
        computed: {
            typeFields() {
                let typeFields = {};
                if (this.offer.type_id){
                    let data = this.options.offerTypesFields[this.offer.type_id];
                    _.defaultsDeep(typeFields, data.fields);
                    _.defaultsDeep(typeFields, data.dummy);
                    if(this.item.fields){
                        _.defaultsDeep(typeFields, this.item.fields);
                    } else {
                        _.defaultsDeep(typeFields, this.offer.fields);
                    }
                }
                return typeFields;
            },

            formTitle() {
                return !this.item.id
                    ? this.trans('pages.offerDescription.newDialogTitle')
                    : this.trans('pages.offerDescription.editDialogTitle');
            },
        }
    }
</script>
